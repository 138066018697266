import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>
            <span className="icon fa-amazon">&nbsp;</span>
            Amazon Music
          </h2>
          <p>Sessions West Playlist</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Coming 2021 ... Sessions West Playlist at Amazon Music</h3>
          {/* <iframe
            id="AmazonMusicEmbed1a09335f-d74e-404b-b334-55f37046fdee"
            src="https://music.amazon.com/embed/1a09335f-d74e-404b-b334-55f37046fdee/?id=HegA3z6Kwb&marketplaceId=ATVPDKIKX0DER&musicTerritory=US"
            width="100%"
            height="550px"
          ></iframe> */}
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
